import * as React from 'react'

import { Row, Typography, Icon, Column, useTheme } from '@wicadu/arepa/ui'
import { useWindowWidth } from '@wicadu/arepa/hooks'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import Consts from '@utils/constants'
import { Translations } from '@ts-types/Translations'
import { usePageContext } from '@HOCs/Providers/PageContext'

const translate: Translations = {
  es: {
    MORE: 'Ver más',
  },
  en: {
    MORE: 'Learn more',
  },
  pt: {},
}

interface Props {
  title: string
  description?: string
  url?: string
  icon: string
  className?: string
}

export { Props as TopicCardProps }

const defaultProps: Partial<Props> = {
  title: '',
  description: '',
  url: '',
  icon: '',
  className: '',
}

function TopicCard(props: Props) {
  const { title, description, icon, url, className } = {
    ...defaultProps,
    ...props,
  }

  const { colors } = useTheme()
  const { lang } = usePageContext()
  const width = useWindowWidth()

  const isNotDesktop = React.useMemo(() => width < MEDIUM_DEVICE, [width])

  return (
    <Container
      className={className}
      {...(isNotDesktop ? { as: 'a', href: url } : {})}
    >
      <StyledIcon name={icon} size={45} withBackground={0.5} />

      <Column>
        <StyledLabel
          type="default"
          size={16}
          weight={700}
          numberOfLines={1}
          children={title}
        />
        <StyledLabel
          type="description"
          size={12}
          numberOfLines={1}
          children={description}
        />
      </Column>

      <StyledLink to={url} as="div">
        {translate[lang].MORE}
        <Icon name="arrow_forward_ios" size={10} color={colors.MAIN.PRIMARY} />
      </StyledLink>
    </Container>
  )
}

const { EXTRA_SMALL_DEVICE, SMALL_DEVICE, MEDIUM_DEVICE } = Consts.breakpoints

const Container = styled(Row)`
  display: flex;
  gap: 10px;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.NEUTRAL.SIDE};
  width: 100%;
  text-decoration: inherit;
  cursor: pointer;

  @media screen and (min-width: ${SMALL_DEVICE}px) {
    max-width: ${EXTRA_SMALL_DEVICE * 1.2}px;
  }

  @media screen and (min-width: ${MEDIUM_DEVICE}px) {
    width: 100%;
    max-width: 245px;
    min-height: 150px;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: initial;

    p {
      -webkit-line-clamp: 2;
    }
  }
`

const StyledIcon = styled(Icon)`
  ${({ name }) => !name && 'display: none;'}

  min-width: 45px;

  @media screen and (min-width: ${MEDIUM_DEVICE}px) {
    min-width: 45px;
    width: 45px;
    height: 45px;
    font-size: 25px;
  }
`

const StyledLabel = styled(Typography)``

const StyledLink = styled(Link)`
  display: none;

  @media screen and (min-width: ${MEDIUM_DEVICE}px) {
    text-decoration: inherit;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    color: ${({ theme }) => theme?.colors?.MAIN?.PRIMARY};
  }
`

export default TopicCard
