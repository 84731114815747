import * as React from 'react'

import { Typography, Column } from '@wicadu/arepa/ui'
import styled from '@emotion/styled'

import TopicsLinkList from '@organisms/TopicsLinkList'
import useTemplate from '@hooks/useTemplate'
import { type HomeScreen } from '@ts-types/Pages/Home'
import Layout from '@HOCs/Layout/Layout'
import SupportSection from '@organisms/SupportSection'
import Consts from '@utils/constants'
import AuthCallToAction from '@molecules/AuthCallToAction'
import TopicCard from '@molecules/TopicCard'
import SearchArticles from '@encapsulators/SearchArticles'

function HomeTemplate() {
  const { translate, lang, topics, trending } = useTemplate<HomeScreen>()

  return (
    <Layout>
      <StyledPageGrid>
        <StyledHero>
          <StyledTitle
            type="title"
            size={32}
            align="center"
            children={translate[lang].WELCOME_MESSAGE as string}
          />

          <SearchArticles />
        </StyledHero>

        <AuthCallToAction />

        <Layout.Section title={translate[lang].TOPICS_TITLE as string}>
          <TopicsSectionContent gap={15}>
            {topics?.map(({ title, icon, description, url }, index: number) => (
              <TopicCard
                key={index}
                icon={icon}
                title={title}
                description={description}
                url={url}
              />
            ))}
          </TopicsSectionContent>
        </Layout.Section>

        <TopicsLinkList {...trending} />

        <SupportSection />
      </StyledPageGrid>
    </Layout>
  )
}

const { SMALL_DEVICE, MEDIUM_DEVICE } = Consts.breakpoints

const StyledPageGrid = styled(Layout.PageGrid)`
  & > div {
    gap: 35px;
  }

  @media screen and (min-width: ${SMALL_DEVICE}px) {
    & > div {
      gap: 50px;
    }
  }
`

const StyledHero = styled.header`
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
`

const StyledTitle = styled(Typography)`
  @media screen and (min-width: ${SMALL_DEVICE}px) {
    font-size: 36px;
    line-height: 40px;
  }
`

const TopicsSectionContent = styled(Column)`
  @media screen and (min-width: ${SMALL_DEVICE}px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    gap: 15px;
  }

  @media screen and (min-width: ${MEDIUM_DEVICE}px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
  }
`

export default HomeTemplate
