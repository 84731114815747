import * as React from 'react'

import { Column, Typography, Icon, useTheme } from '@wicadu/arepa/ui'
import { hexToRGBA } from '@wicadu/arepa/utils'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import Consts from '@utils/constants'

interface Props {
  outlined?: boolean
  title: string
  description?: string
  actionText?: string
  icon?: string
  url?: string
  className?: string
}

const defaultProps: Partial<Props> = {
  outlined: false,
  title: '',
  description: '',
  actionText: '',
  icon: '',
  url: '',
  className: '',
}

function ContactCard(props: Props) {
  const { title, description, icon, actionText, outlined, url, className } = {
    ...defaultProps,
    ...props,
  }

  const { colors } = useTheme()

  const contentColor: string = React.useMemo(
    () => (outlined ? colors.FONT.CONTRAST : colors.FONT.TITLE),
    [colors, outlined]
  )

  return (
    <Container outlined={outlined} className={className}>
      <StyledIcon name={icon} size={20} color={contentColor} />

      <StyledTitle
        type="title-4"
        size={18}
        children={title}
        color={contentColor}
      />
      <Typography
        type="default"
        size={14}
        color={contentColor}
        children={description}
      />

      <StyledLink to={url} outlined={outlined}>
        {actionText}

        <Icon
          name="arrow_forward_ios"
          size={10}
          color={outlined ? colors.FONT.CONTRAST : colors.MAIN.PRIMARY}
        />
      </StyledLink>
    </Container>
  )
}

const { EXTRA_SMALL_DEVICE, SMALL_DEVICE } = Consts.breakpoints

const Container = styled(Column)`
  border-radius: 10px;
  padding: 24px;
  background-color: ${({ theme, outlined }) =>
    outlined
      ? theme.colors.MAIN.PRIMARY
      : hexToRGBA(theme.colors.NEUTRAL.SIDE, 0.5)};
  width: 100%;

  @media screen and (min-width: ${SMALL_DEVICE}px) {
    max-width: ${EXTRA_SMALL_DEVICE * 1.2}px;
  }
`

const StyledIcon = styled(Icon)`
  padding: 3px;
  border: 3px solid ${({ color }) => color};
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  ${({ name }) => !name && 'display: none;'}
`

const StyledTitle = styled(Typography)`
  @media screen and (min-width: ${SMALL_DEVICE}px) {
    font-size: 18px;
    line-height: 22px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: inherit;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  color: ${({ theme, outlined }) =>
    outlined ? theme.colors.FONT.CONTRAST : theme?.colors?.MAIN?.PRIMARY};
  margin-top: 10px;
`

export default ContactCard
