import React from 'react'

import type { PageProps } from 'gatsby'

import HomePageTemplate from '@templates/Home'
import { TemplateProvider } from '@hooks/useTemplate'
import HeadSEO from '@atoms/HeadSEO'
import { PAGES_ID } from '@config/pages/const'
import { resolvePagePath } from '@utils/resolvePagePath'
import { usePageContext } from '@HOCs/Providers/PageContext'
import Consts from '@utils/constants'
import { type TopicCardProps } from '@molecules/TopicCard'
import { type IPageProps } from '@ts-types/Page'
import { type Translations } from '@ts-types/Translations'
import { getUrlByPageId } from '@utils/getUrlByPageId'

interface Props extends IPageProps {}

const {
  HOME_TRANSLATIONS,
  ORDERS_HOME_TRANSLATION,
  LEGAL_HOME_TRANSLATION,
  LEGAL_PRIVACY_POLICY_TRANSLATION,
  LEGAL_TERMS_OF_USE_TRANSLATION,
} = Consts.translates

const _TOPICS_LIST = [ORDERS_HOME_TRANSLATION, LEGAL_HOME_TRANSLATION]

const _TRENDING_LIST = [
  LEGAL_PRIVACY_POLICY_TRANSLATION,
  LEGAL_TERMS_OF_USE_TRANSLATION,
]

const HomePage: React.FC<PageProps> = () => {
  const { lang } = usePageContext()

  const topicsIconMap: Record<string, string> = {
    [PAGES_ID.ORDERS_HOME]: 'receipt',
    [PAGES_ID.LEGAL_HOME]: 'description',
  }

  const topics: TopicCardProps[] = _TOPICS_LIST.map((t: Translations) => ({
    icon: topicsIconMap[t[lang].PAGE_ID as string],
    title: t[lang].TITLE as string,
    url: resolvePagePath(t[lang].PAGE_ID, lang)?.pathname || '',
    description: t[lang].DESCRIPTION as string,
  }))

  const trending = {
    title: HOME_TRANSLATIONS[lang].TRENDING_TITLE,
    items: _TRENDING_LIST.map((t: Translations) => ({
      label: t[lang].ARTICLE['TITLE'],
      url: resolvePagePath(t[lang].PAGE_ID, lang)?.pathname || '',
      content: t[lang].ARTICLE['DESCRIPTION'],
    })),
  }

  return (
    <TemplateProvider
      value={{
        translate: HOME_TRANSLATIONS,
        lang,
        topics,
        trending,
      }}
    >
      <HomePageTemplate />
    </TemplateProvider>
  )
}

export function Head({ pageContext }: Props) {
  const { lang } = pageContext || {}
  const { organization, logo } = Consts.schemeOrg

  const topicsList = _TOPICS_LIST.map((t: Translations, index: number) => ({
    '@type': 'CollectionPage',
    position: index + 1,
    name: t[lang].TITLE,
    description: t[lang].DESCRIPTION,
    url: getUrlByPageId(t[lang].PAGE_ID, lang),
    about: {
      '@type': 'WebPage',
      name: t[lang].TITLE,
    },
  }))

  const trendingArticleList = _TRENDING_LIST.map(
    (t: Translations, index: number) => ({
      '@type': 'Article',
      position: index + 1,
      name: t[lang].ARTICLE['TITLE'],
      headline: t[lang].ARTICLE['TITLE'],
      description: t[lang].ARTICLE['DESCRIPTION'],
      url: getUrlByPageId(t[lang].PAGE_ID, lang),
      datePublished: '2025-03-23T00:00:00Z',
      dateModified: '2025-03-23T00:00:00Z',
      author: organization.organizationField,
      publisher: organization.organizationField,
      image: logo.logoField,
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': getUrlByPageId(t[lang].PAGE_ID, lang),
      },
      isPartOf: {
        '@type': 'WebSite',
        name: HOME_TRANSLATIONS[lang].SEO_TITLE as string,
        url: getUrlByPageId(PAGES_ID.HOME, lang),
      },
      keywords: (t[lang].KEYWORDS as string) || '',
    })
  )

  return (
    <>
      <HeadSEO
        omitSuffix
        title={HOME_TRANSLATIONS[lang].SEO_TITLE as string}
        description={HOME_TRANSLATIONS[lang].SEO_DESCRIPTION as string}
        lang={lang}
        pageId={PAGES_ID.HOME}
        jsonLd={[
          {
            '@type': 'WebSite',
            '@id': getUrlByPageId(PAGES_ID.HOME, lang),
            name: HOME_TRANSLATIONS[lang].SEO_TITLE,
            description: HOME_TRANSLATIONS[lang].SEO_DESCRIPTION,
            url: getUrlByPageId(PAGES_ID.HOME, lang),
            potentialAction: {
              '@type': 'SearchAction',
              target: `${process.env.HELP_WEBAPP_HOST}/search?q={search_term_string}`,
              'query-input': 'required name=search_term_string',
            },
            publisher: organization.organizationField,
            keywords: HOME_TRANSLATIONS[lang].SEO_KEYWORDS as string,
          },
          {
            '@type': 'ItemList',
            name: HOME_TRANSLATIONS[lang].TOPICS_TITLE,
            itemListOrder: 'https://schema.org/ItemListOrderDescending',
            itemListElement: topicsList,
          },
          {
            '@type': 'ItemList',
            itemListOrder: 'https://schema.org/ItemListOrderDescending',
            name: HOME_TRANSLATIONS[lang].TRENDING_TITLE,
            itemListElement: trendingArticleList,
          },

          // {
          //   '@type': 'ItemList',
          //   itemListOrder: 'https://schema.org/ItemListOrderDescending',
          //   name: HOME_TRANSLATIONS[lang].SUGGESTED_TITLE,
          //   itemListElement: [],
          // },

          // TODO: ADD FAQ IN UI AND HERE
          // {
          //   '@type': 'FAQPage',
          //   '@id': process.env.HELP_WEBAPP_HOST,
          //   mainEntity: [
          //     {
          //       '@type': 'Question',
          //       name: 'How can I track my order?',
          //       acceptedAnswer: {
          //         '@type': 'Answer',
          //         text: 'You can track your order by visiting the Orders section and entering your tracking number.',
          //       },
          //     },
          //     {
          //       '@type': 'Question',
          //       name: 'What are the Terms of Use?',
          //       acceptedAnswer: {
          //         '@type': 'Answer',
          //         text: 'By using wicadu.com, you accept the following terms and conditions of use. Read more here: https://wicadu.com/terms-of-use',
          //       },
          //     },
          //     {
          //       '@type': 'Question',
          //       name: 'How do I contact Wicadu support?',
          //       acceptedAnswer: {
          //         '@type': 'Answer',
          //         text: 'If you need assistance, our support team is ready to help. Contact us here: https://wicadu.com/help/contact',
          //       },
          //     },
          //   ],
          // },
        ]}
      />
    </>
  )
}

export default HomePage
