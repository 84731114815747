import * as React from 'react'

import { Column, Typography, Button } from '@wicadu/arepa/ui'
import styled from '@emotion/styled'

import Consts from '@utils/constants'
import { Translations } from '@ts-types/Translations'
import { usePageContext } from '@HOCs/Providers/PageContext'
import useAuth from '@hooks/useAuth'

const translate: Translations = {
  es: {
    TITLE: 'Inicia sesión para obtener ayuda más rápidamente',
    SIGN_IN: 'Iniciar sesión',
  },
  en: {
    TITLE: 'Sign in to get help faster',
    SIGN_IN: 'Sign In',
  },
  pt: {},
}

interface Props {
  className?: string
}

const defaultProps: Partial<Props> = {
  className: '',
}

function AuthCallToAction(props: Props) {
  const { className } = {
    ...defaultProps,
    ...props,
  }

  const { lang } = usePageContext()
  const { isSignedIn, login } = useAuth()

  if (isSignedIn) return null

  return (
    <Container gap={15} className={className}>
      <StyledTitle
        type="default"
        align="center"
        size={18}
        weight={700}
        children={translate[lang].TITLE}
      />

      <StyledButton
        margin="0 auto"
        onClick={login}
        children={translate[lang].SIGN_IN}
      />
    </Container>
  )
}

const { SMALL_DEVICE, MEDIUM_DEVICE } = Consts.breakpoints

const Container = styled(Column)`
  width: 100%;

  @media screen and (min-width: ${MEDIUM_DEVICE}px) {
    margin: 0 auto;
    max-width: ${SMALL_DEVICE}px;
    flex-direction: row;
    align-items: center;
  }
`

const StyledTitle = styled(Typography)`
  @media screen and (min-width: ${SMALL_DEVICE}px) {
    font-size: 18px;
    line-height: 22px;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 300px;

  @media screen and (min-width: ${MEDIUM_DEVICE}px) {
    max-width: 250px;
    margin: 0;
  }
`

export default AuthCallToAction
