import React, { useMemo } from 'react'

import { Form } from '@wicadu/arepa/ui'
import { yupResolver, yup } from '@wicadu/arepa/utils'
import styled from '@emotion/styled'

type Props = {
  component: React.ElementType
}

const schema = yup.object().shape({
  search: yup.string().max(300, 'No puede ingresar más de 300 caracteres'),
})

function withSearchForm<T extends Props>(
  WrappedComponent: React.FC<T>
): React.FC<T> {
  const WithSearchForm: React.FC<T> = (props) => {
    const opts = useMemo(
      () => ({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
        defaultValues: {
          search: '',
        },
      }),
      []
    )

    return (
      <StyledForm
        opts={opts}
        itemScope
        itemType="https://schema.org/SearchAction"
      >
        <WrappedComponent {...props} />
      </StyledForm>
    )
  }

  return WithSearchForm
}

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  justify-content: center;
`

export default withSearchForm
