import * as React from 'react'

import { SearchInput } from '@wicadu/arepa/ui'
import styled from '@emotion/styled'

import Consts from '@utils/constants'
import withSearchForm from '@HOCs/Form/Search'
import { usePageContext } from '@HOCs/Providers/PageContext'

const { HOME_TRANSLATIONS } = Consts.translates

function SearchArticles() {
  const { lang } = usePageContext()

  return (
    <StyledSearchInput
      name="search"
      withBorder
      disabled
      placeholder={HOME_TRANSLATIONS[lang].SEARCH_PLACEHOLDER as string}
      aria-label={HOME_TRANSLATIONS[lang].SEARCH_PLACEHOLDER as string}
    />
  )
}

const { SMALL_DEVICE, MEDIUM_DEVICE } = Consts.breakpoints

const StyledSearchInput = styled(SearchInput)`
  width: 100%;
  max-width: ${SMALL_DEVICE}px;

  @media screen and (min-width: ${SMALL_DEVICE}px) {
    width: 90%;
  }

  @media screen and (min-width: ${MEDIUM_DEVICE}px) {
    width: ${MEDIUM_DEVICE}px;
  }
`

export default withSearchForm(SearchArticles) as React.FC
