import * as React from 'react'

import { Section } from '@wicadu/arepa/ui'
import styled from '@emotion/styled'

import { usePageContext } from '@HOCs/Providers/PageContext'
import { Translations } from '@ts-types/Translations'
import Consts from '@utils/constants'
import ContactCard from '@molecules/ContactCard'
import { resolvePagePath } from '@utils/resolvePagePath'
import { PAGES_ID } from '@config/pages/const'

const translate: Translations = {
  es: {
    TITLE: '¿Todavía necesitas ayuda?',
    DESCRIPTION: `Si no has encontrado las respuestas que buscas, nuestro equipo de soporte está aquí para ayudarte.`,
    CONTACT_US_TITLE: 'Contacta con nosotros',
    CONTACT_US_DESCRIPTION:
      '¿Necesitas ayuda? Nuestro equipo de soporte está listo para asistirte',
    CONTACT_US_CALL_TO_ACTION: 'Ponte en contacto',
  },
  en: {
    TITLE: 'Still need help?',
    DESCRIPTION: `If you haven't found the answers you're looking for, our support team is here to help.`,
    CONTACT_US_TITLE: 'Contact us',
    CONTACT_US_DESCRIPTION:
      'Need assistance? Our support team is ready to help',
    CONTACT_US_CALL_TO_ACTION: 'Get in Touch',
  },
  pt: {},
}

function SupportSection() {
  const { lang } = usePageContext()

  return (
    <Container
      title={translate[lang].TITLE}
      titleProps={{
        type: 'title-3',
        size: 24,
        lineHeight: 28,
      }}
      description={translate[lang].DESCRIPTION}
      descriptionProps={{
        type: 'default',
        size: 16,
        lineHeight: 22,
      }}
    >
      <StyledContactCard
        icon="question_mark"
        url={resolvePagePath(PAGES_ID.CONTACT_US, lang)?.pathname as string}
        title={translate[lang].CONTACT_US_TITLE as string}
        description={translate[lang].CONTACT_US_DESCRIPTION as string}
        actionText={translate[lang].CONTACT_US_CALL_TO_ACTION as string}
      />
    </Container>
  )
}

const { SMALL_DEVICE } = Consts.breakpoints

const Container = styled(Section)`
  width: 100%;
  gap: 25px;

  @media screen and (min-width: ${SMALL_DEVICE}px) {
    h2[type='title-4'] {
      font-size: 24px !important;
      line-height: 32px !important;
    }

    p[data-description='true'] {
      font-size: 16px !important;
      line-height: 22px !important;
    }
  }
`

const StyledContactCard = styled(ContactCard)`
  margin-top: 15px;
`

export default SupportSection
